<!-- 登录组件 -->
<template>
  <div class="login-wrap" :lang="$i18n.locale">
		<el-header class="login-header">
			<!-- zpy 2022-7-21 VIP国际化 选择语言 -->
			<el-dropdown style="margin-right: 15px; float:right;font-size: 18px;" @command="handleCommand">
				<span class="el-dropdown-link">{{$t('setting.languageType')}}<i class="el-icon-arrow-down el-icon--right"></i></span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="zh">Zh - 中文</el-dropdown-item>
					<el-dropdown-item command="en">En - English</el-dropdown-item>
					<el-dropdown-item command="ar">Ar - بالعربية</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</el-header>
    <el-form :model="loginUser" :rules="rules" ref="loginUser" class="container" style>
      <div
        class="layadmin-user-login-box layadmin-user-login-header"
        style="width: 326px; font-size:36px;display:flex;justify-content:center;align-items:center;margin-bottom:10px"
      >
        <img src="../assets/images/FleeTan.png" alt style="width:70%" />
      </div>
      <el-form-item prop="user_name">
        <el-input v-model="loginUser.user_name" prefix-icon="el-icon-user" :placeholder="$t('loginPage.loginName_ph')"></el-input>
      </el-form-item>
      <el-form-item prop="login_password">
        <el-input
          v-model="loginUser.login_password"
          show-password
          prefix-icon="el-icon-unlock"
          :placeholder="$t('loginPage.loginPwd_ph')"
          @keyup.enter.native="submitForm('loginUser')"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item style="margin-bottom:8px">
        <router-link :to="'/changePass'" tag="a" class="forget_password">忘记密码？</router-link>
      </el-form-item>-->

      <el-form-item>
        <el-button
          type="primary"
          class="login-btn enter"
          @click="submitForm('loginUser')"
          style="background-color:#139322;outline:none;border-color:#fff;"
        >{{$t('loginPage.loginBtn')}}</el-button>
      </el-form-item>
      <!-- <el-form-item style="text-align:right">
        <span style="font-size:14px">Powered By</span>
        <img
          src="@/assets/images/logo.jpg"
          alt
          style="width:30%;margin-left:10px;vertical-align: middle;"
        />
      </el-form-item> -->
      <el-form-item class="typecap" style="margin-bottom:12px;visibility:hidden">
        <div class="flexBet">
          <el-input
            class="code"
            prefix-icon="el-icon-key"
            v-model="loginUser.code"
            placeholder="请输入验证码"
            @keyup.enter.native="submitForm('loginUser')"
          ></el-input>
          <div class="indentify flexStart" @click="getImage">
            <!-- <Identify :defaultCode="idenntifyCode" /> -->
            <img class="image" src alt />
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Identify from "@/components/Identify.vue";
import { changeLanguage } from '@/assets/i18n/index'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Identify
  },
  data() {
    //这里存放数据
    return {
      loginUser: {
        user_name: "",
        login_password: "",
        code: ""
      },
      //验证码
      idenntifyCodes: "124567890abcdefghjkmnpqrst",
      idenntifyCode: "",
      uuid: "" // 传递到后端的值
    };
  },
	computed:{
		rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
		  //自定义验证规则 暂不需要验证码
		  // const validateCode = (rule, value, callback) => {
		  //   if (value === "") {
		  //     callback(new Error("请输入验证码"));
		  //   } else {
		  //     if (value != this.idenntifyCode) {
		  //       callback(new Error("验证码输入不正确"));
		  //     }
		  //     callback();
		  //   }
		  // };
			return {
				user_name: [
				  { required: true, message: this.$t('loginPage.loginName_ph'), trigger: "blur", }
				],
				login_password: [
				  { required: true, message: this.$t('loginPage.loginPwd_ph'), trigger: "blur" }
				],
				// code: [{ validator: validateCode, trigger: "blur" }]
			}
		}
	},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // if (this.loginUser.code.length === 0) {
          //   return this.$message.warning("请输入验证码");
          // }
          //请求数据
          let params = {
            username: this.loginUser.user_name,
            password: this.loginUser.login_password,
            code: this.loginUser.code,
            uuid: this.uuid
          };
          // console.log(params);
          //将对象转换成JSON字符串
          var oParams = JSON.stringify(params);
          // console.log(oParams);
          // 将JSON字符串存储到session中
          window.sessionStorage.setItem("params", oParams);

          const res = await this.$http.post("/api/login", params);
          if (res.code == "500") {
            this.$message.error(res.msg);
            this.getImage();
          } else if (res.code == "-2") {
            this.$message.error(this.$t('loginPage.loginErrorInfo'));
          } else if (res.code == "200") {
            window.sessionStorage.setItem("token", res.token);
            this.$router.push({ path: "/" });
            this.$message.success(this.$t('loginPage.loginSuccessInfo'));
          } else {
            this.$message.error(this.$t('loginPage.loginOtherError'));
          }
        }
      });
    },
    //生成一个随机数 Identify.vue
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.idenntifyCode = "";
      for (let i = 0; i < 4; i++) {
        this.idenntifyCode += this.idenntifyCodes[
          this.randomNum(0, this.idenntifyCodes.length)
        ];
      }
    },

    // 获取验证码图片
    async getImage() {
      const res = await this.$http.get("/api/captchaImage");
      if (res.code === 200) {
        // (this.obj.image = ),
        // (this.obj.uuid = res.uuid);
        // this.obj = {
        //   image: "data:image/png;base64," + res.img,
        //   uuid: res.uuid,
        // };
        document.querySelector(".image").src =
          "data:image/png;base64," + res.img;
        this.uuid = res.uuid;
        this.loginUser.code = res.captcha;
      }
    },
		// VIP中英文切换显示
		handleCommand(type){
			const loading = this.$loading({
				lock:true,
				text:'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			
			changeLanguage(type);
			localStorage.setItem('vipLang',type);
			
			setTimeout(function(){
				loading.close();
			},1500)
		},
  },
  mounted() {
    this.getImage();
    this.refreshCode();
  },
  async created() {
    // const res = await this.$http.get("/captchaImage");
    // console.log(res);
		
  }
};
</script>
<style scoped>
.el-button:hover {
  margin: 0 !important;
}
.login-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.login-header{
	width: 100%;
	height: 60px;
	line-height: 60px;
	position: fixed;
	top: 0px;
}
.typecap {
  display: flex;
}
.container {
  width: 326px;
  height: auto;
  margin: 0 auto;
  background: #fff;
  padding: 30px 32px;
  position: absolute;
  left: 0;
  right: 0;
  top: 8%;
  background-color: #fff;
}
.code {
  width: 60% !important;
  float: left;
}
.indentify {
  float: right;
}
.login-btn {
  width: 100%;
}
.indentify {
  width: 108px;
}
.indentify img {
  width: 100%;
}
.login-btn {
  width: 100%;
}
.forget_password {
  padding-left: 5px;
  text-decoration: none;
  color: #333;
}
.forget_password:hover {
  color: red;
}

[lang='ar'] /deep/ .el-input__prefix {
	right: 5px;
	left: auto;
}
[lang='ar'] /deep/ .el-input__suffix {
	left: 5px;
	right: auto;
}
[lang='ar'] /deep/ .el-input--prefix .el-input__inner{
	padding-right: 30px;
}
[lang='ar'] /deep/ .el-input--suffix .el-input__inner{
	padding-right: 30px;
}


</style>