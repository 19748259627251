<!-- 验证码 -->
<template>
  <div class="canvas">
    <canvas id="canvas" :width="contentWidth" :height="contentHeight"></canvas>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  props: {
    contentWidth: {
      //宽度
      type: Number,
      default: 120
    },
    contentHeight: {
      //高度
      type: Number,
      default: 40
    },
    backgroundColorMin: {
      //背景颜色最小值
      type: Number,
      default: 180
    },
    backgroundColorMax: {
      //背景颜色最大值
      type: Number,
      default: 240
    },
    textColorMin: {
      type: Number,
      default: 50
    },
    textColorMax: {
      type: Number,
      default: 150
    },
    fontSizeMin: {
      type: Number,
      default: 35
    },
    fontSizeMax: {
      type: Number,
      default: 40
    },
    defaultCode: {
      type: String,
      default: "1234"
    }
  },
  methods: {
    //生成一个随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    //随机一个颜色
    //一个颜色值rgb(0-255,0-255,0-255)
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    drawPic() {
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      ctx.textBaseline = "bottom"; //设置或返回在绘制文本时使用的当前文本基线
      //绘制一个背景
      ctx.fillStyle = this.randomColor(
        //填充一个颜色
        this.backgroundColorMin,
        this.backgroundColorMax
      );
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight); //绘制矩形
      //执行绘制文本的方法  defaultCode在props中定义的
      for (let i = 0; i < this.defaultCode.length; i++) {
        this.drawText(ctx, this.defaultCode[i], i); //this.defaultCode[i]  1,2,3,4
      }
      //   执行干扰点
      this.drawDots(ctx);
    },
    //绘制文本的方法
    drawText(ctx, text, i) {
      ctx.fillStyle = this.randomColor(this.textColorMin, this.textColorMax);
      ctx.font =
        this.randomNum(this.fontSizeMin, this.fontSizeMin) + "px Georgia";
      let x = (i + 1) * (this.contentWidth / (this.defaultCode.length + 1));
      let y = this.randomNum(this.fontSizeMin, this.contentHeight);
      let deg = this.randomNum(-45, 45); //文字旋转的角度
      //修改文字的原点和坐标
      ctx.translate(x, y);
      ctx.rotate((deg * Math.PI) / 180);
      ctx.fillText(text, 0, 0);
      //  恢复坐标点和角度 注意顺序
      ctx.rotate((-deg * Math.PI) / 180);
      ctx.translate(-x, -y);
    },
    //绘制干扰点
    drawDots(ctx) {
      //绘制干扰点多个
      for (let i = 0; i < 100; i++) {
        ctx.fillStyle = this.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight),
          1,
          0,
          2 * Math.PI
        );
        ctx.fill();
      }
    }
  },
  watch: {
    defaultCode() {
      this.drawPic();
    }
  },
  mounted() {
    this.drawPic();
  }
};
</script>
<style>
</style>